@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.inputWrap {
  display: flex;
  width: 100%;

  .input {
    width: 100%;
    max-width: 192px;
    height: 40px;
    padding: 10px;
    background: $color-gray-80;
    border: none;
    border-radius: 6px;
    font-family: Pretendard;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray-800;
    outline: none;

    &.alpha {
      width: 100%;
      max-width: 95px;
      margin-left: 9px;
      padding-left: 25px;
    }

    &.disabled {
      color: $color-gray-400;
      pointer-events: none;
    }
  }
}
