@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
$header-padding: 40px;

.area {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 $header-padding;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  & .logo {
    flex-shrink: 0;
  }

  &.hidden {
    transform: translateY(-100%);
  }

  & > .menu-container {
    display: flex;
    align-items: center;
  }

  &.border {
    border-bottom: 1px solid $color-gray-400;

    &.darkMode {
      border-bottom: 1px solid $color-gray-700;
    }
  }

  .image-content {
    position: relative;
    max-width: 500px;
    margin-right: 24px;

    &.VERTICAL {
      max-height: 48px;
    }

    &.HORIZONTAL {
      max-height: 32px;
    }
  }

  .company-name {
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    font-size: 1px;
    color: transparent;
    top: 0;
    z-index: -1;
  }

  .default-logo {
    display: flex;
    align-items: center;

    &.darkMode {
      color: white;
    }

    .default-logo-text {
      margin-left: 4px;
    }
  }

  .listWrap {
    display: flex;
    gap: 40px;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: transparent;

    .listItem {
      display: block;

      &.FOLDER {
        display: block;
        height: 100%;

        .link {
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-tap-highlight-color: transparent !important;
        }
      }

      .link {
        position: relative;
        padding: 8px 16px;
        margin: 11px 0;
        border-radius: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #121619;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent !important;

        &:hover {
          .depth-2 {
            visibility: visible;
          }
        }

        &:not(.ignore-hover) {
          &:hover {
            background-color: $color-gray-80;
          }

          &.DARK:hover {
            background-color: $color-gray-700;
          }
        }

        .depth-2 {
          list-style: none;
          visibility: hidden;
          position: absolute;
          min-width: 150px;
          top: 41px;
          padding: 10px 6px;
          background: $color-white;
          border: 1px solid $color-gray-400;
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.05);
          border-radius: 6px;
          z-index: 200;

          .subMenu {
            width: 100%;
            height: 32px;
            padding: 6px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $color-gray-800;
            white-space: nowrap;

            &:hover {
              background: $color-gray-80;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  .drawerWrap {
    position: absolute;
    display: none;
    top: 64px;
    right: 0;
    margin: 0;
    width: 100%;
    background: $color-white;
    list-style: none;
    transform: translate(100%, 0);
    transition: all 0.3s;
    z-index: 20;
    overflow-y: scroll;
    @include hidden-scroll;

    .listItem {
      display: block;
      margin: 0;

      &.active {
        background-color: $color-gray-80;
      }

      ~ .listItem {
        margin-top: 16px;
      }

      .link {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.01em;
        color: #121619;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent !important;
      }

      .title-wrap {
        display: flex;
        align-items: center;

        padding-left: 16px;

        .icon {
          margin-left: 8px;
        }
      }

      .depth-2 {
        display: none;
        flex-direction: column;
        gap: 8px;
        padding: 0;
        margin: 16px 0 0 0;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        list-style: none;

        &.open {
          display: flex;
        }

        .subMenu {
          display: flex;
          align-items: center;
          gap: 4px;
          padding-left: 32px;

          &.active {
            background-color: $color-gray-80;
          }
        }
      }
    }
  }

  .menuBtn {
    position: absolute;
    right: 40px;
    height: 32px;
    background: none;
  }

  .language-menu {
    display: flex;
    margin: 0 28px;

    .language {
      @include font('b2', false);
      line-height: 16px;
      color: $color-gray-400;
      cursor: pointer;

      &.active {
        font-weight: 700;
        color: $color-gray-800;
        &.DARK {
          color: $color-white;
        }
      }

      &:first-child {
        padding-right: 10px;
      }

      &:not(:first-child) {
        border-left: 1px solid $color-gray-300;
        padding-left: 10px;
      }
    }
  }
}

@import 'ViewHeaderTablet.module';
@import 'ViewHeaderMobile.module';
